import axios from 'axios';
import _ from 'lodash';
import config from '@/config/env-constants';

export default {
    baseUrl: 'https://us-central1-ayun-quantity-' + config.currEnv + '.cloudfunctions.net',

    getConnections(filterBy, view, currUserId) {
        const url = `${this.baseUrl}/getConnections`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },
}